<template>
    <div class="custom-list">
      <div class="row mb-5 mt-5 justify-content-between w-100">
        <div class="col-6">
          <input
            placeholder="Ara.."
            type="text"
            class="form-control"
            v-model="search"
          />
        </div>
        <button @click=" addFileModal=true "class="btn btn-main-success">Ekle</button>
  
        <b-modal v-model="addFileModal" title="Teknik Destek Ekle">
          <div class="form-group">
              <label for="">Talep Dosya Adı</label>
              <input
            class="w-100 mt-3"
            type="text"
            style="border: 1px solid rgba(128, 128, 128, 0.521); height: 35px; border-radius: 5px;"
          />
          </div>
          <div class="form-group">
              <label for="">Talep Dosyası Ekle</label>
              <b-form-file
            v-model="file1"
            placeholder="Dosya Ekleyiniz"
            drop-placeholder="Drop file here..."
            class="mt-5"
          ></b-form-file>
          </div>
          
          <template #modal-footer>
              <button class="btn btn-secondary" @click="addFileModal=false">Vazgeç</button>            
              <button class="btn btn-success" @click="addFileModal=false">Ekle</button>
          </template>
        </b-modal>
      </div>
      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Teknik Destek"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
       
        <template v-slot:item.dosya="{ item }">
          <a :href="gorsel" target="_blank">Dosyayı Görüntüle</a>
        </template>
        <template v-slot:item.action="{ item }">
          <div>
            <b-icon
              icon="trash-fill"
              aria-hidden="true"
              class="trashIcon"
              @click="dosyaSil(item)"
            ></b-icon>
          </div>
        </template>
      </List>
      <!-- Create and ısOrtagiCalisanAdis Popups -->
    </div>
  </template>
  <script>
  export default {
    name: "customerList",
  
    data() {
      return {
        gorsel: "https://picsum.photos/id/237/200/300",
        search: "",
        filters: "",
        showInsertUpdate: false,
        showDetail: false,
        showImportModal: false,
        resetForm: false,
        confirmCreate: false,
        loading: false,
        badgesEnum: ["green", "yellow", "red"],
        stepNames: ["Hazırlık", "Satış", "Devir"],
        header: [
          { text: "", value: "id", checkboxField: true, size: "100px" },
          { text: "ID", value: "id" },
          { text: "Teknik Destek Id", sortable: true, value: "teknikDestekId" },
          { text: "Teknik Destek Teklif Adı", sortable: true, value: "teknikDestekTeklifAdi" },
          { text: "Dosya", value: "dosya", size: "200px" },
          { text: "İşlemler", value: "action", size: "100px" },
        ],
        items: [],
        pagination: {},
        meta: {},
        listSelectedItemIds: [],
        selectedItem: "",
        deleteOperationUrl: "",
        deleteIds: "",
        filterList: [],
        list_length: 5,
        addFileModal:false
      };
    },
  
    created() {
      this.search = this.$route.query.search;
      this.search = this.search.toLowerCase();
      this.getList();
    },
    methods: {
      getList() {
        this.loading = true;
        this.items = [
          {
            id: 1,
            teknikDestekId: "Musluk Bozuldu of",
            dosya: "",
            teknikDestekTeklifAdi: "Teknik Destek Teklif Adı",
          },
          {
            id: 2,
            teknikDestekId: "Su Tesisatı",
            dosya: "",
            teknikDestekTeklifAdi: "Teknik Destek Teklif Adı",
          },
          {
            id: 3,
            teknikDestekId: "Ocak Tamiri",
            dosya: "",
            teknikDestekTeklifAdi: "Teknik Destek Teklif Adı",
          },
          {
            id: 4,
            teknikDestekId: "Teknik Destek Adı",
            dosya: "",
            teknikDestekTeklifAdi: "Teknik Destek Teklif Adı",
          },
          {
            id: 5,
            teknikDestekId: "Teknik Destek Adı",
            dosya: "",
            teknikDestekTeklifAdi: "Teknik Destek Teklif Adı",
          },
          {
            id: 6,
            teknikDestekId: "Teknik Destek Adı",
            dosya: "",
            teknikDestekTeklifAdi: "Teknik Destek Teklif Adı",
          },
        ];
        this.totalItems = this.items.length;
  
        this.pagination = {
          current_page: 1,
          total_pages: Math.ceil(this.items?.length / this.list_length),
          total_items: this.items.length,
          list_length: this.list_length,
        };
        this.loading = false;
        if (this.search) {
          const searchValue = this.search;
          const foundItems = this.items.filter((item) => {
            for (const prop in item) {
              if (item[prop].toString().toLowerCase().includes(searchValue)) {
                return true;
              }
            }
            return false;
          });
          this.items = foundItems;
        }
      },
      dosyaSil(item) {
        this.items = this.items.filter((i) => i.id !== item.id);
      },
      destekEkle(ekle){
  
      }
    },
    watch: {
      meta: {
        handler() {
          this.getList();
        },
        deep: true,
      },
      search: function () {
        this.getList();
      },
      list_length: function () {
        this.getList();
      },
      $route: function () {
        this.search = this.$route.query.search;
        this.search = this.search.toLowerCase();
      },
    },
  };
  </script>
  
  <style lang="scss">
  .nav-tabs {
    border-bottom: 0 !important;
    div {
      width: 100%;
  
      li {
        flex: 1;
  
        a {
          display: flex;
          justify-content: center;
          margin: 0 auto !important;
          font-weight: 600;
          letter-spacing: 0.5px;
          font-size: 1rem;
          color: $dveb-text-color !important;
          border-bottom: 5px solid $dveb-main-gray !important;
  
          &.active {
            border-bottom: 5px solid $dveb-main-red !important;
          }
        }
      }
    }
  }
  
  .tab-content {
    width: 100%;
  }
  
  .tab-area {
    display: flex;
    flex-direction: column;
  
    .tab-content {
      display: flex;
      align-items: center;
  
      img {
        background-color: red;
        border-radius: 50%;
        margin-right: 3rem;
        border: 2px solid $dveb-main-red;
      }
  
      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .custom-pagination {
    div {
      width: auto !important;
    }
  }
  .trashIcon {
    color: $dveb-main-red;
    font-size: 18px;
  }
  </style>
  